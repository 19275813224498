var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-list",
    {
      scopedSlots: _vm._u([
        {
          key: "dropdownItens",
          fn: function() {
            return [
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function() {
                      return (_vm.showUpload = !_vm.showUpload)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.showUpload ? "Fechar" : "Upload lote") +
                      " "
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.showUpload
        ? _c(
            "vue-dropzone",
            {
              ref: "myVueDropzone",
              attrs: {
                id: "dropzone",
                "use-custom-slot": true,
                options: _vm.dropzoneOptions
              },
              on: { "vdropzone-complete-multiple": _vm.completeUpload }
            },
            [
              _c("div", { staticClass: "dropzone-custom-content" }, [
                _c("i", {
                  staticClass: "display-4 text-muted bx bxs-cloud-upload"
                }),
                _c("h4", [
                  _vm._v("Solte os arquivos aqui ou clique para fazer upload.")
                ])
              ]),
              _c("div", { staticClass: "text-center mt-4" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "button" } },
                  [_vm._v(" Enviar Arquivos ")]
                )
              ])
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "row mt-4 mb-2" }, [
        _c("div", { staticClass: "col-sm-12 col-md-2" }, [
          _c(
            "label",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _c("span", { staticClass: "d-none d-sm-inline" }, [
                _vm._v("Mostrar")
              ]),
              _c("b-form-select", {
                staticClass: "ml-2 mr-1",
                attrs: { size: "sm", options: _vm.$tableItens },
                on: { input: _vm.getSearch },
                model: {
                  value: _vm.paginacao.itensPorPagina,
                  callback: function($$v) {
                    _vm.$set(_vm.paginacao, "itensPorPagina", $$v)
                  },
                  expression: "paginacao.itensPorPagina"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-sm-12 col-md-3" }, [
          _c(
            "label",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _vm._v(" Hospital: "),
              _c("b-form-input", {
                directives: [
                  {
                    name: "debounce",
                    rawName: "v-debounce:450",
                    value: _vm.getSearch,
                    expression: "getSearch",
                    arg: "450"
                  }
                ],
                staticClass: "ml-2 form-control form-control-sm ms-2 ",
                attrs: { type: "search" },
                model: {
                  value: _vm.filter.hospital,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "hospital", $$v)
                  },
                  expression: "filter.hospital"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-sm-12 col-md-2" }, [
          _c(
            "label",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _vm._v(" Lote: "),
              _c("b-form-input", {
                directives: [
                  {
                    name: "debounce",
                    rawName: "v-debounce:450",
                    value: _vm.getSearch,
                    expression: "getSearch",
                    arg: "450"
                  }
                ],
                staticClass: "ml-2 form-control form-control-sm ms-2 ",
                attrs: { type: "number" },
                model: {
                  value: _vm.filter.lote,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "lote", $$v)
                  },
                  expression: "filter.lote"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-sm-12 col-md-4" }, [
          _c("div", { staticClass: "d-inline-flex align-items-center" }, [
            _c(
              "label",
              { staticClass: " d-inline-flex align-items-center" },
              [
                _vm._v(" Período: "),
                _c("FDateInput", {
                  on: {
                    change: function($event) {
                      return _vm.getSearch()
                    }
                  },
                  model: {
                    value: _vm.filter.periodoStart,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "periodoStart", $$v)
                    },
                    expression: "filter.periodoStart"
                  }
                })
              ],
              1
            ),
            _c("span", { staticClass: "mx-1 pl-1 pr-1" }, [_vm._v(" a ")]),
            _c(
              "label",
              { staticClass: "d-inline-flex align-items-center fw-normal" },
              [
                _c("FDateInput", {
                  staticClass: "ml-2 ",
                  on: {
                    change: function($event) {
                      return _vm.getSearch()
                    }
                  },
                  model: {
                    value: _vm.filter.periodoEnd,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "periodoEnd", $$v)
                    },
                    expression: "filter.periodoEnd"
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c("b-table", {
            attrs: {
              striped: "",
              hover: "",
              small: "",
              items: _vm.items,
              busy: _vm.loading,
              fields: _vm.colunas
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function() {
                  return [_c("ui-loading")]
                },
                proxy: true
              },
              {
                key: "cell(arquivoNome)",
                fn: function(data) {
                  return [
                    data.item.descricaoErro != ""
                      ? _c(
                          "span",
                          {
                            staticClass: "text-danger c-pointer",
                            attrs: {
                              title: "Clique para exibir detalhes do erro"
                            },
                            on: {
                              click: function($event) {
                                return _vm.exibeErro(data.item.descricaoErro)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-times fa-lg pe-1" }),
                            _vm._v(" " + _vm._s(data.item.arquivoNome) + " ")
                          ]
                        )
                      : _c("span", [
                          _vm._v(" " + _vm._s(data.item.arquivoNome) + " ")
                        ])
                  ]
                }
              },
              {
                key: "cell(dataAdd)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("dataHora")(data.item.dataAdd)) + " "
                    )
                  ]
                }
              },
              {
                key: "cell(action)",
                fn: function(data) {
                  return [
                    !data.item.descricaoErro
                      ? _c(
                          "b-button-group",
                          { attrs: { size: "sm" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "outline-primary",
                                  title: "ver"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push({
                                      name: "faturamento.lotes.detalhe",
                                      params: {
                                        id: data.item.id,
                                        backTo: _vm.$router.currentRoute.path
                                      }
                                    })
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "uil uil-eye icon-size"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            {
              staticClass: "dataTables_paginate paging_simple_numbers float-end"
            },
            [
              _c(
                "ul",
                { staticClass: "pagination pagination-rounded" },
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.paginacao.TotalPaginas,
                      "per-page": 1
                    },
                    on: { input: _vm.getResults },
                    model: {
                      value: _vm.paginacao.pagina,
                      callback: function($$v) {
                        _vm.$set(_vm.paginacao, "pagina", $$v)
                      },
                      expression: "paginacao.pagina"
                    }
                  })
                ],
                1
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }