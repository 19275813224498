<template>
  <card-list>
    <template #dropdownItens>
      <b-dropdown-item
        href="#"
        @click="() => (showUpload = !showUpload)"
      >
        {{ showUpload ? "Fechar" : "Upload lote" }}
      </b-dropdown-item>
    </template>

    <vue-dropzone
        v-if="showUpload"
        id="dropzone"
        ref="myVueDropzone"
        :use-custom-slot="true"
        :options="dropzoneOptions"
        @vdropzone-complete-multiple="completeUpload"
      >
        <div class="dropzone-custom-content">
          <i class="display-4 text-muted bx bxs-cloud-upload"></i>
          <h4>Solte os arquivos aqui ou clique para fazer upload.</h4>
        </div>
        <div class="text-center mt-4">
          <button type="button" class="btn btn-primary">
            Enviar Arquivos
          </button>
        </div>
    </vue-dropzone>

    <div class="row mt-4 mb-2">
      <div class="col-sm-12 col-md-2">
        <label class="d-inline-flex align-items-center">
          <span class="d-none d-sm-inline">Mostrar</span>
          <b-form-select
            class="ml-2 mr-1"
            v-model="paginacao.itensPorPagina"
            size="sm"
            :options="$tableItens"
            @input="getSearch"
          ></b-form-select>
        </label>
      </div>
      <div class="col-sm-12 col-md-3">
        <label class="d-inline-flex align-items-center">
          Hospital:
          <b-form-input
            v-model="filter.hospital"
            v-debounce:450="getSearch"
            type="search"
            class="ml-2 form-control form-control-sm ms-2 "
          ></b-form-input>
        </label>
      </div>
      <div class="col-sm-12 col-md-2">
        <label class="d-inline-flex align-items-center">
          Lote:
          <b-form-input
            v-model="filter.lote"
            v-debounce:450="getSearch"
            type="number"
            class="ml-2 form-control form-control-sm ms-2 "
          ></b-form-input>
        </label>
      </div>
      <div class="col-sm-12 col-md-4">
        <div class="d-inline-flex align-items-center">
          <label class=" d-inline-flex align-items-center">
            Período:&nbsp;&nbsp;
            <FDateInput
              v-model="filter.periodoStart"
              @change="getSearch()"
            />
          </label>
          <span class="mx-1 pl-1 pr-1"> a </span>
          <label class="d-inline-flex align-items-center fw-normal">
            <FDateInput
              v-model="filter.periodoEnd"
              @change="getSearch()"
              class="ml-2 "
            />
          </label>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <b-table 
          striped hover small 
          :items="items" 
          :busy="loading"
          :fields="colunas">
        <template #table-busy>
          <ui-loading></ui-loading>
        </template>
        <template #cell(arquivoNome)="data">
          <span v-if="data.item.descricaoErro != ''" class="text-danger c-pointer" title="Clique para exibir detalhes do erro" @click="exibeErro(data.item.descricaoErro)">
            <i class="fas fa-times fa-lg pe-1"></i> {{ data.item.arquivoNome }}
          </span>
          <span v-else>
            {{ data.item.arquivoNome }}
          </span>
        </template>
        <template #cell(dataAdd)="data">
          {{ data.item.dataAdd | dataHora }}
        </template>
        <template #cell(action)="data">
          <b-button-group v-if="!data.item.descricaoErro" size="sm">
            <b-button
              variant="outline-primary"
              title="ver"
              @click="$router.push({name: 'faturamento.lotes.detalhe', params: {id: data.item.id, backTo: $router.currentRoute.path }})"
            >
              <i class="uil uil-eye icon-size"></i>
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="paginacao.pagina"
              :total-rows="paginacao.TotalPaginas"
              :per-page="1"
              @input="getResults"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </card-list>

</template>

<script>
import CardList from "@/components/Ui/CardList";
import appConfig from "@/app.config";
import vue2Dropzone from "vue2-dropzone";
import FDateInput from '@/components/Form/DateInput';
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { authHeader } from "../../../services/auth-header";
import { getAllUploads } from "../../../services/faturamento.service";

export default {
  page: {
    title: "Upload Lotes de faturamento",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    CardList, FDateInput,
    vueDropzone: vue2Dropzone
  },
  computed: {
    dropzoneOptions() {
      return {
        url: process.env.VUE_APP_APIURL + "arquivos/uploads",
        thumbnailWidth: 150,
        // maxFilesize: 0.5,
        autoProcessQueue: true,
        autoQueue: true,
        uploadMultiple: true,
        acceptedFiles: "application/xml, text/xml",
        paramName: "arquivos",
        headers: authHeader(),
      };
    },
  },
  data() {
    return {
      showUpload: false,
      filter: {
        hospital: '',
        lote: '',
        periodoStart: null,
        periodoEnd: null,
      },
      colunas: [
        { key: "arquivoNome", label: "Nome do Arquivo" },
        { key: "lote", label: "Lote" },
        { key: "hospital", label: "Hospital" },
        { key: "convenio", label: "Convênio" },
        { key: "dataAdd", label: "Data" },
        { key: "versaoTiss", label: "Versão" },
        { key: "action", label: "Ações", thClass: 'list-act-bar', tdClass: 'list-act-bar' },
      ],
      periodo: {},
      items: [],
      paginacao: {
        pagina: 1,
        itensPorPagina: this.$tableItensDefault,
        TotalPaginas: 1,
        totalRegistros: 1,
      },
      ordenacao: {},
      loading: true
    };
  },
  mounted() {
    this.getSearch();
  },
  methods: {
    exibeErro(erro) {
      this.$swal.fire('', erro, 'error');
    },
    getParam() {
      return Object.assign(
        this.filter,
        {
          paginacao: this.paginacao,
        }
      );
    },
    getSearch() {
      this.paginacao.pagina = 1;
      this.getResults();
    },
    getResults() {
      this.loading = true
      getAllUploads(this.getParam()).then(({ data }) => {
        this.items = data.resultado;
        this.paginacao.pagina = data.paginacao.pagina;
        this.paginacao.TotalPaginas = data.paginacao.totalPaginas;
        this.paginacao.itensPorPagina = data.paginacao.itensPorPagina;
        this.paginacao.totalRegistros = data.paginacao.totalRegistros;
        this.loading = false
      });
    },
    completeUpload(response) {
      var file = response[0];
      var jsonResult = JSON.parse(file.xhr.responseText)
      var arquivosErro = jsonResult.filter(f => !f.sucesso).map(r => '<li>' + r.arquivo + ': ' + r.mensagem + '</li>')

      if (arquivosErro.length > 0) {
        this.$swal.fire('', '<div class="text-start">Os seguintes não serão processados, veja mais detalhes na lista de uploads: <ul class="fs-6">'+arquivosErro.join('')+'</ul></div>', 'error');
      }
      this.getSearch()
    }
  },
};
</script>

